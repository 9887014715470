import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { NetworkUrlUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/NetworkUrlUpdateCommand'
import { OrganizationUrlCreateCommand } from '@rallycry/api-suite-typescript/dist/models/OrganizationUrlCreateCommand'
import { OrganizationUrlResource } from '@rallycry/api-suite-typescript/dist/models/OrganizationUrlResource'
import { Form, Formik, FormikHelpers } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { v4 as uuidv4 } from 'uuid'
import { ConfirmingButton } from '../interactive/ConfirmingButton'
import { RcButton } from '../interactive/RcButton'
import { AvatarText } from '../text/AvatarText'
import { RcIconPicker } from './RcIconPicker'
import { RcFileInput } from './RcFileInput'
import { useApiError } from '@/core/hooks/useApiError'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { ImageDisplay } from '@/components/atoms/ImageDisplay'

export interface UrlProps {
  create: (cmd: OrganizationUrlCreateCommand) => Promise<any>
  update: (id: number, cmd: NetworkUrlUpdateCommand) => Promise<any>
  remove: (id: number) => Promise<void>
  allowSiteNav?: boolean
}

export const ManageUrls = ({
  urls,
  ...rest
}: { urls?: OrganizationUrlResource[] } & UrlProps) => {
  return (
    <Stack direction='column' spacing={3}>
      {urls?.map(it => <UrlRow key={it.id!} url={it} {...rest} />)}
    </Stack>
  )
}

// handle old and new data
// "trophy" -> ["fal", "trophy"]
// "fal-trophy" -> ["fal", "trophy"]
// "arrow-circle-right" -> ["fal", "arrow-circle-right"]
// "fal-arrow-circle-right" -> ["fal", "arrow-circle-right"]
// "fab-twitter" ->   ["fab", "twitter"]
export const extractIconStrings = (inputString?: string) => {
  // if starts with "fal-" or "fab-"
  if (inputString?.startsWith('fal-') || inputString?.startsWith('fab-')) {
    const hyphenIndex = inputString?.indexOf('-')
    const firstPart = inputString?.substring(0, hyphenIndex)
    const secondPart =
      inputString?.substring(hyphenIndex! + 1) || 'question-circle'
    return [firstPart, secondPart] as IconProp
  }
  // otherwise assume "fal-"
  else {
    return ['fal', inputString || 'question-circle'] as IconProp
  }
}

export const UrlRow = ({
  url,
  ...rest
}: {
  url: OrganizationUrlResource
} & UrlProps) => {
  const { getNetworkString, getNetworkIcon } = useNetworkKinds()

  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item xs>
        <ModalTrigger
          activation={handleOpen => (
            <AvatarText
              variant='rounded'
              onClick={handleOpen}
              src={url.image}
              avatarChildren={
                url.options === 'sponsor' && url.image ? (
                  <ImageDisplay path={url.image} />
                ) : (
                  <RcIcon
                    icon={
                      url.description
                        ? extractIconStrings(url.description)
                        : getNetworkIcon(url.network)
                    }
                    size='xl'
                  />
                )
              }
            >
              <Stack direction='row' spacing={3} alignItems='center'>
                <Typography variant='h3'>{url.name}</Typography>
                <Typography variant='subtitle2' color='text.secondary'>
                  {url.options === 'none' ? (
                    <RcTrans i18nKey='shared.quick-link' />
                  ) : url.options === 'sponsor' ? (
                    <RcTrans i18nKey='shared.sponsor' />
                  ) : (
                    <RcTrans i18nKey='shared.site-nav' />
                  )}
                </Typography>
              </Stack>
              <Typography variant='body2'>
                <NavigationLink to={url.url} onClick={e => e.stopPropagation()}>
                  {url.url}
                </NavigationLink>
              </Typography>
            </AvatarText>
          )}
        >
          {({ handleClose }) => (
            <UrlForm initial={url} onComplete={handleClose} {...rest} />
          )}
        </ModalTrigger>
      </Grid>
      <Grid item>
        <ConfirmingButton
          message={<RcTrans i18nKey='error.confirmation.social-links' />}
          buttonName={<RcTrans i18nKey='shared.delete' />}
          icon={['fal', 'trash']}
          onClick={() => rest.remove(url.id!)}
        />
      </Grid>
    </Grid>
  )
}

export const UrlForm = ({
  initial,
  create,
  update,
  onComplete,
  allowSiteNav
}: {
  initial?: OrganizationUrlResource
  onComplete: () => void
} & UrlProps) => {
  const { handle } = useApiError()
  const handleSubmit = async (
    values: OrganizationUrlCreateCommand,
    helpers: FormikHelpers<OrganizationUrlCreateCommand>
  ) => {
    try {
      // handle "none selected" for formik, which does not like empty string
      initial ? await update(initial.id!, values) : await create(values)
      helpers.resetForm()
      onComplete()
    } catch (error) {
      await handle(error, { values, helpers })
    }

    return Promise.resolve()
  }

  const validation = Yup.object<OrganizationUrlCreateCommand>({
    name: Yup.string(),
    description: Yup.string(),
    image: Yup.string().url(),
    url: Yup.string().url().required(),
    ordinal: Yup.number(),
    options: Yup.string(),
    network: Yup.mixed<NetworkKind>().oneOf(Object.values(NetworkKind))
  })

  return (
    <Formik
      validationSchema={validation}
      initialValues={
        {
          name: '',
          description: '',
          image: '',
          url: '',
          ordinal: 50,
          network: NetworkKind.WEBSITE,
          ...initial,
          options: initial?.options || 'none'
        } as OrganizationUrlCreateCommand
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form id='manage-urls-form'>
          <Grid container direction='row' spacing={3}>
            <Grid item xs={6}>
              <LabeledField
                component={Select}
                name='options'
                variant='outlined'
                label={<RcTrans i18nKey='shared.kind' />}
                fullWidth
              >
                <MenuItem value='none'>
                  <RcTrans i18nKey='shared.quick-link' />
                </MenuItem>
                <MenuItem value='sponsor'>
                  <RcTrans i18nKey='shared.sponsor' />
                </MenuItem>
                {allowSiteNav ? (
                  <MenuItem value='highlight'>
                    <RcTrans i18nKey='shared.site-nav' />
                  </MenuItem>
                ) : null}
              </LabeledField>
            </Grid>
            <Grid item xs={12}>
              <LabeledField
                component={TextField}
                name='name'
                type='text'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.name' />}
              />
            </Grid>
            <Grid item xs={12}>
              <LabeledField
                component={TextField}
                name='url'
                type='text'
                variant='outlined'
                fullWidth
                url={'true'}
                label={<RcTrans i18nKey='shared.url' />}
              />
            </Grid>

            {values.options === 'sponsor' ? (
              <Grid item xs={12}>
                <Stack direction={'column'} spacing={2}>
                  <LabeledField
                    component={TextField}
                    name='image'
                    type='text'
                    variant='outlined'
                    fullWidth
                    url={'true'}
                    label={<RcTrans i18nKey='shared.image' />}
                    description={
                      <RcTrans i18nKey='shared.image-instructions' />
                    }
                  />
                  <Box sx={{ aspectRatio: 480 / 270 }}>
                    <RcFileInput
                      saveAs={`org-url-${initial?.id || uuidv4()}`}
                      source={values.image}
                      aspectRatio={480 / 270}
                      onComplete={async url => setFieldValue('image', url)}
                      onClear={async () => setFieldValue('image', '')}
                    />
                  </Box>
                </Stack>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <RcIconPicker
                name='description'
                label={<RcTrans i18nKey='shared.icon' />}
              />
            </Grid>
            <ModalConfiguration>
              <RcButton
                type='submit'
                disabled={isSubmitting}
                form='manage-urls-form'
                fullWidth
                variant='contained'
              >
                {initial ? (
                  <RcTrans i18nKey='shared.update' />
                ) : (
                  <RcTrans i18nKey='shared.submit' />
                )}
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
