import {
  createTheme,
  CSSInterpolation,
  Shadows,
  Theme,
  ThemeOptions
} from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import Color from 'color'
import { times } from 'lodash-es'
import { fontFamily } from './fontsV4'
import { TEXT_PALETTE } from './palette'
import { BACKGROUND_PALETTE } from './palette'
import { calcElevationString } from './palette'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}

  interface PaletteOptions {
    surface: {
      main: string
    }
  }

  interface PaletteVariantsOptions {
    surface: {
      main: string
    }
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraLarge: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraLarge: true
  }
}
declare module '@mui/material/styles' {
  interface TypographyVariants {
    body0: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body0: true
    h5: false
    h6: false
  }
}

const createBase = (options: ThemeOptions) =>
  createTheme(
    deepmerge(
      {
        palette: {
          info: { main: '#B2B2B2' },
          warning: { main: '#FF7A00' },
          error: { main: '#FF3B30' },
          success: { main: '#36B37E' },
          surface: { main: '#FFFFFF' }
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1500
          }
        },
        shadows: times(25, () => 'none') as Shadows,
        spacing: 4,
        typography: fontFamily as any
      },
      options
    )
  )

interface ThemeOrgFeatures {
  stylePrimaryBtn?: boolean
  styleSecondaryBtn?: boolean
}

export const defaultThemeV4 = (
  options: ThemeOptions,
  orgFeatures?: ThemeOrgFeatures
) => {
  const base = createBase(options)
  const mode = base.palette.mode
  const isDarkMode = mode === 'dark'
  const colorPrimary = base.palette.primary.main

  const background = {
    default: BACKGROUND_PALETTE.default[base.palette.mode],
    paper: BACKGROUND_PALETTE.paper[base.palette.mode],
    dialog: BACKGROUND_PALETTE.dialog[base.palette.mode]
  }

  const text = {
    primary: TEXT_PALETTE.primary[base.palette.mode],
    secondary: TEXT_PALETTE.secondary[base.palette.mode],
    disabled: TEXT_PALETTE.disabled[base.palette.mode]
  }

  const typography = fontFamily as any

  const elevations: Record<string, CSSInterpolation> = {}
  for (let i = 0; i < 16; i++) {
    elevations[`elevation${i}`] = {
      backgroundImage: calcElevationString(mode, [i])
    }
  }

  let primaryButton = isDarkMode ? '#90caf9' : '#1976d2'
  if (orgFeatures?.stylePrimaryBtn) {
    primaryButton = base.palette.primary.main
  }
  let secondaryButton = isDarkMode ? '#868686' : '#ededed'
  if (orgFeatures?.styleSecondaryBtn) {
    secondaryButton = isDarkMode
      ? base.palette.secondary.dark
      : base.palette.secondary.light
  }

  const primaryFade = Color(primaryButton)
    .mix(Color(background.default), 0.75)
    .rgb()
    .string()

  const secondaryFade = Color(primaryButton)
    .darken(0.5)
    .mix(Color(background.default), 0.25)
    .rgb()
    .string()

  const infoFade = Color(base.palette.info.main)
    .mix(Color(background.default), 0.75)
    .rgb()
    .string()

  const tooltipColor = '#000000E5'
  const innerOptions: ThemeOptions = {
    palette: {
      background,
      text
    },
    shape: { borderRadius: 5 },
    typography,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            margin: 0,
            background: 'black',
            overscrollBehavior: 'none',
            touchAction: 'pan-y pinch-zoom',
            overflowX: 'hidden',
            WebkitFontSmoothing: 'antialiased',
            MozFontSmoothing: 'antialiased',
            OFontSmoothing: 'antialiased',
            WebkitTextSizeAdjust: 'auto !important',
            overflowY: 'auto',
            scrollbarGutter: 'stable',
            // hide arrows on number inputs in webkit
            '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0
              },
            // hide arrows on number inputs in Firefox
            '& input[type=number]': {
              MozAppearance: 'textfield'
            },
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              width: 8,
              height: 8,
              backgroundColor: 'transparent',
              background: 'transparent'
            },
            '& *::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
              background: 'transparent'
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
              background: 'transparent'
            },
            '&::-webkit-scrollbar': {
              width: 8,
              borderRadius: 0
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: colorPrimary,
              backgroundImage: calcElevationString(mode, [6, 6])
            },
            '&::-webkit-scrollbar, & *::-webkit-scrollbar-corner': {
              backgroundColor: 'transparent',
              background: 'transparent'
            }
          },
          a: {
            color: base.palette.primary.main
          },
          '& .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected':
            {
              backgroundColor: base.palette.primary.main
            },
          ul: {
            paddingInlineStart: 24
          },
          li: {
            lineHeight: '1.5rem'
          },
          hr: {
            border: 'none',
            borderBottom: `1px solid ${base.palette.primary.main}`
          }
        }
      },
      MuiAlert: {
        defaultProps: { elevation: 9 },
        styleOverrides: {
          root: {
            ...base.typography.body2,
            alignItems: 'center'
          },
          action: {
            alignItems: 'center',
            padding: 0,
            paddingLeft: 15,
            whiteSpace: 'nowrap'
          },
          icon: { alignItems: 'center', color: 'inherit' },
          filledInfo: {
            color: base.palette.info.main
          },
          filledError: {
            color: base.palette.error.main
          },
          filledSuccess: {
            color: base.palette.success.main
          },
          filledWarning: {
            color: base.palette.warning.main
          },

          outlinedInfo: {
            backgroundColor: base.palette.info.main
          },
          outlinedError: {
            backgroundColor: base.palette.error.main
          },
          outlinedSuccess: {
            backgroundColor: base.palette.success.main
          },
          outlinedWarning: {
            backgroundColor: base.palette.warning.main
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '& .MuiAccordionSummary-expandIconWrapper': {
              marginRight: base.spacing(3)
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              padding: `${base.spacing(1)} ${base.spacing(2)}`
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
              padding: 0,
              paddingLeft: 15,
              paddingRight: 10
            }
          },
          paper: {
            backgroundImage: calcElevationString(mode, [5])
          },
          endAdornment: { display: 'flex', flexDirection: 'row' }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          rounded: {
            borderRadius: base.spacing(2)
          }
        }
      },
      MuiIconButton: {
        variants: [
          {
            props: { size: 'extraLarge' },
            style: {
              height: '64px',
              paddingLeft: base.spacing(3),
              paddingRight: base.spacing(3),
              paddingTop: base.spacing(2),
              paddingBottom: base.spacing(2),
              ...typography.body1,
              fontWeight: 600
            }
          }
        ]
      },
      MuiButton: {
        variants: [
          {
            props: { size: 'extraLarge' },
            style: {
              height: '64px',
              paddingLeft: base.spacing(3),
              paddingRight: base.spacing(3),
              paddingTop: base.spacing(2),
              paddingBottom: base.spacing(2),
              ...typography.body1,
              fontWeight: 600
            }
          }
        ],
        defaultProps: {
          variant: 'contained'
        },
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 500,
            borderRadius: '50px',
            minWidth: 0, // allow "round" icon buttons
            '&.rc-navigation-button': {
              ...typography.h3,
              fontFamily: 'var(--font-poppins)',
              height: 48,
              justifyContent: 'flex-start',
              overflow: 'hidden',
              paddingLeft: base.spacing(5),
              paddingRight: base.spacing(5),
              position: 'relative',
              width: '100%',
              zIndex: 0,
              '&:before': {
                background: colorPrimary,
                backgroundImage: calcElevationString(mode, [0, 8, 3, 3]),
                content: '""',
                height: '100%',
                position: 'absolute',
                right: -2,
                top: 0,
                transform: 'scaleX(0)',
                transition: 'transform .3s ease-in',
                transformOrigin: 'right',
                width: '100%',
                zIndex: -1
              },
              '&.active:before': {
                transform: 'scaleX(1.5)',
                transition: 'transform .5s ease-out'
              },
              '&.active,:hover': {
                color: 'inherit'
              }
            }
          },
          containedPrimary: {
            backgroundColor: primaryButton,
            color: base.palette.getContrastText(primaryButton),
            '&:hover': {
              backgroundColor: Color(primaryButton).darken(0.1).hex()
            },
            '&.selected': {
              backgroundColor: Color(primaryButton).darken(0.1).hex()
            }
          },
          containedSecondary: {
            backgroundColor: secondaryButton,
            color: base.palette.getContrastText(secondaryButton),
            '&:hover': {
              backgroundColor: Color(secondaryButton).darken(0.1).hex()
            }
          },
          outlinedPrimary: {
            color: text.primary,
            borderColor: primaryButton
          },
          outlinedSecondary: {
            color: text.primary,
            bordercolor: secondaryButton
          },
          //base.spacing(1) = 4
          sizeLarge: {
            height: '43px',
            paddingLeft: base.spacing(3),
            paddingRight: base.spacing(3),
            paddingTop: base.spacing(2),
            paddingBottom: base.spacing(2),
            ...typography.body1,
            fontWeight: 600
          },
          sizeMedium: {
            height: '38px',
            paddingLeft: base.spacing(3),
            paddingRight: base.spacing(3),
            paddingTop: base.spacing(2),
            paddingBottom: base.spacing(2),
            ...typography.body1,
            fontWeight: 500
          },
          sizeSmall: {
            height: '24px',
            paddingLeft: base.spacing(3),
            paddingRight: base.spacing(3),
            paddingTop: base.spacing(1),
            paddingBottom: base.spacing(1),
            ...typography.body2,
            fontWeight: 500
          },
          text: {
            backgroundColor: 'transparent',
            color: base.palette.text.primary,
            '&.active': {},
            '&:hover': {}
          },
          containedInherit: {
            color: base.palette.common.black,
            backgroundColor: base.palette.grey[200]
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiTab-textColorPrimary': {
              paddingTop: base.spacing(2),
              paddingBottom: base.spacing(2),
              paddingLeft: base.spacing(3),
              paddingRight: base.spacing(3),
              color: base.palette.text.primary,
              minWidth: 0,
              '&:hover': {
                background: base.palette.primary.main,
                backgroundImage: calcElevationString(mode, [2])
              },
              '&.Mui-selected': {
                backgroundColor: primaryButton,
                backgroundImage: calcElevationString(mode, [6]),
                color: base.palette.text.primary
              }
            }
          }
        }
      },
      MuiChip: {
        defaultProps: { size: 'small' },
        styleOverrides: {
          root: {
            alignItems: 'center',
            cursor: 'inherit',
            borderRadius: 15,
            padding: 5,
            ...base?.typography?.body2,
            '&.MuiChip-colorPrimary': {
              backgroundColor: primaryFade,
              color: isDarkMode
                ? Color(primaryFade).lighten(2.4).rgb().string()
                : Color(primaryFade).darken(0.6).rgb().string()
            },
            '&.MuiChip-colorSecondary': {
              backgroundColor: secondaryFade,
              color: isDarkMode
                ? Color(secondaryFade).lighten(1.4).rgb().string()
                : Color(secondaryFade).darken(0.6).rgb().string()
            },
            '&.MuiChip-colorSuccess': {
              backgroundColor: Color(base.palette.success.main)
                .fade(0.75)
                .rgb()
                .string(),
              color: base.palette.success.main
            },
            '&.MuiChip-colorInfo': {
              backgroundColor: infoFade,
              color: isDarkMode
                ? Color(infoFade).lighten(1.5).rgb().string()
                : Color(infoFade).darken(0.7).rgb().string()
            },
            '&.MuiChip-colorWarning': {
              backgroundColor: Color(base.palette.warning.main)
                .fade(0.75)
                .rgb()
                .string(),
              color: isDarkMode
                ? base.palette.warning.main
                : Color(base.palette.warning.main).darken(0.2).rgb().string()
            },
            '&.MuiChip-colorError': {
              backgroundColor: Color(base.palette.error.main)
                .fade(0.75)
                .rgb()
                .string(),
              color: isDarkMode
                ? base.palette.error.main
                : Color(base.palette.error.main).darken(0.1).rgb().string()
            }
          },
          icon: {
            fontSize: 14,
            display: 'flex'
          },
          deleteIcon: {
            color: 'inherit',
            '&:hover': { color: 'inherit', filter: 'brightness(55%)' }
          },
          label: { pointerEvents: 'none' } // allow clickthru for id tracking
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            border: 'none'
          }
        }
      },
      MuiFormControl: { defaultProps: { fullWidth: true } },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            color: base.palette.text.secondary,
            borderRadius: base.shape.borderRadius * 4,
            '&.MuiLink-root': {
              color: base.palette.text.secondary
            },
            '&.MuiButtonBase-root': {
              borderRadius: base.shape.borderRadius * 4,
              backgroundColor: 'transparent',
              transition: 'none',
              '&:hover': {
                backgroundColor: base.palette.primary.main,
                backgroundImage: calcElevationString(mode, [2])
              }
            }
          } // for react-router active link
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            ...typography.body1,
            backgroundColor: base.palette.background.paper,
            backgroundImage: calcElevationString(mode, [4]),
            '&:not(.Mui-focused,.Mui-error,:hover) fieldset': {
              border: 'none'
            }
          }
        }
      },
      MuiMenu: {
        // aero glass theme
        styleOverrides: {
          paper: {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(10px)'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: base.shape.borderRadius * 5,
            padding: `${base.spacing(1)} ${base.spacing(2)}`
          },
          input: {
            padding: `${base.spacing(1)} ${base.spacing(2)}`,
            display: 'flex',
            alignItems: 'center'
          },
          adornedStart: {
            paddingLeft: 15
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          elevation: {
            backgroundColor:
              options?.palette?.background?.paper || colorPrimary,
            boxShadow: 'none'
          },
          ...elevations
        }
      },
      MuiSelect: {
        styleOverrides: { select: { display: 'flex', alignItems: 'center' } }
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: '6px 8px 10px 6px'
          },
          track: {
            border: `1px solid ${base.palette.grey[600]}`,
            backgroundColor: base.palette.primary.main
          }
        }
      },

      MuiTableContainer: {
        styleOverrides: { root: { padding: `0 16px` } }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&:first-of-type': {
              paddingLeft: 16,
              borderTopLeftRadius: base.shape.borderRadius * 4,
              borderBottomLeftRadius: base.shape.borderRadius * 4
            },
            '&:last-of-type': {
              paddingRight: 16,
              borderTopRightRadius: base.shape.borderRadius * 4,
              borderBottomRightRadius: base.shape.borderRadius * 4
            }
          },
          alignRight: { justifyContent: 'flex-end' }
        }
      },
      MuiTableRow: {
        defaultProps: { hover: true },
        styleOverrides: {
          root: {
            '&.selected': {
              backgroundColor: base.palette.action.selected
            },
            '&:is(:last-child,:hover, .selected,:has(+ tr:hover),:has(+ tr.selected)) .MuiTableCell-root':
              {
                borderColor: 'transparent'
              }
          }
        }
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable',
          allowScrollButtonsMobile: true,
          scrollButtons: true
        },
        styleOverrides: {
          root: {
            minHeight: 0,
            mb: 1,
            '& .Mui-disabled': { display: 'none' }
          },
          scrollButtons: {
            width: 30
          },
          indicator: { display: 'none' }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: 0,
            padding: 2,
            borderRadius: 100,
            textTransform: 'none',
            color: base.palette.text.primary,
            '&.MuiTab-textColorPrimary': {
              minWidth: 65
            }
          }
        }
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
          placement: 'top'
        },
        styleOverrides: {
          arrow: {
            '&:before': {
              border: `1px solid ${base.palette.divider}`
            },
            color: tooltipColor
          },
          tooltip: {
            border: `1px solid ${base.palette.divider}`,
            backgroundColor: tooltipColor,
            color: base.palette.getContrastText(tooltipColor)
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            overflow: 'visible',
            '&.MuiLink-root.hover': {
              '&:hover': {
                color: `color-mix(in srgb, currentColor 85%, black)`
              }
            },
            '& .MuiLink-root': {
              color:
                mode === 'light'
                  ? Color(base.palette.primary.main).darken(0.1).hex()
                  : Color(base.palette.primary.main).lighten(0.3).hex()
            },
            '&.MuiTypography-paragraph img': {
              float: 'left',
              maxWidth: '100%',
              borderRadius: base.spacing(2),
              marginRight: base.spacing(4)
            }
          },
          paragraph: {
            lineHeight: '1.5rem'
          }
        }
      }
    }
  }

  // make sure org can override anything
  const reapplied = deepmerge(innerOptions, options)

  return createTheme(base, reapplied)
}

export const getModalBackground = (theme: Theme) =>
  theme.palette.mode === 'light'
    ? theme.palette.common.white
    : theme.palette.grey[800]

export const getTransition = (
  theme: Theme,
  dimension: 'width' | 'height' | 'top',
  show: boolean
) => {
  return show
    ? theme.transitions.create([dimension], {
        duration: theme.transitions.duration.leavingScreen
      })
    : theme.transitions.create([dimension], {
        duration: theme.transitions.duration.enteringScreen
      })
}
